import {
  ClaimState,
  IClaimViewModel,
} from "@/fe-core/meta/interfaces/user/affiliate/claim";
import { RootState } from "@/fe-core/meta/types/root";
import { userSelector } from "../";

export const userClaimSelector = (state: RootState): ClaimState => {
  console.log("REDUCER STATE", state);
  const { claim } = userSelector(state);
  return claim;
};

export const userClaimDataSelector = (
  state: RootState
): IClaimViewModel | null => {
  const { data } = userClaimSelector(state);
  return data;
};

export const userClaimPendingSelector = (state: RootState): boolean => {
  const { pending } = userClaimSelector(state);
  return pending;
};
