import { IStatus } from "../root";

export interface IGetBonusesPayload {
  type?: string[];
  status?: string;
  additionalData?: string;
}

export interface IBonusResponsePayload {
  status: string;
  bonuses: IBonus[];
}

export interface IBonusResponseViewModal {
  status: string;
  bonuses: IBonus[];
  totalClaimedAmount: number;
}

export interface IEligibleOptInBonusPlansResponsePayload {
  status: string;
  optInBonusPlans: IEligibleOptInBonusPlan[];
}

export interface IDepositEligibleBonusPlansResponsePayload {
  status: string;
  bonusPlans: IDepositEligibleBonusPlan[];
}

export interface IOptInBonusResponsePayload {
  status: string;
  message: string;
}

export interface IBonusViewModel {
  bonuses: IBonus[];
}

export enum triggerTypes {
  CODED = "APPROVED",
  COUPON = "DECLINED",
  DAILY_AWARD = "DAILY_AWARD",
  DEPOSIT = "DEPOSIT",
}

export interface IBonus {
  id: number;
  bonusPlanName: string;
  bonusPlanId: string;
  status: string;
  expiryDate: string;
  expiryDateFormatted: boolean;
  triggerDate: string;
  triggerDateFormatted: string;
  releaseDate: string;
  releaseDateFormatted: string;
  amount: string;
  amountNumber: number;
  wagerRequirement: string;
  wagerRequirementNumber: number;
  amountWagered: string;
  amountWageredNumber: number;
  currency: string;
  triggerType: triggerTypes;
  description: string;
  reference?: {
    completed: number;
    lastAwardDate: string;
    lastAwardDateFormatted: string;
    total: number;
  };
  day?: number;
  key?: any;
  midNightTriggerDate?: any;
}

interface IadditionalAwards {
  type: string
  product: string
  gameId: string
  rounds: number
  coinValue: null
}
export interface IEligibleOptInBonusPlan {
  additionalAwards: IadditionalAwards[] | null;
  bonusPlanId: number;
  optInId: string;
  bonusPlanName: string;
  bonusPlanType: string;
  bonusAmount: number;
  loyaltyAmount: number;
  description: string;
  imageUrl: string;
  startDate: string;
  startDateFormatted: string;
  endDate: string;
  endDateFormatted: string;
}

export interface IDepositEligibleBonusPlan {
  bonusPlanId: number;
  bonusPlanName: string;
  playable: boolean;
  minDepositAmount: string;
  maxDepositAmount: string;
  bonusAmount: number;
  loyaltyAmount: number;
  description: string;
  imageUrl: string;
  startDate: string;
  startDateFormatted: string;
  endDate: string;
  endDateFormatted: string;
}

export interface ICancelBonusPayload {
  bonusId: number;
}

export interface IOptInBonusPayload {
  optInId: string;
  bonusPlanId: number;
}

export interface IOptInBonusActionResponsePayload {
  message: string;
  bonusPlanId: number;
}

export interface IApplyBonusPayload {
  bonusCode: string;
  isTournamentTicket?: boolean | undefined;
}

export interface IRedeemBonusPayload {
  bonusCode: string;
}

export interface IApplyBonusRequestPayload {
  bonusCode: string;
}

export interface IApplyBonusViewModel {
  bonusWalletID: string;
}

export interface IRedeemBonusResponseBonus {
  ID: number;
  bonusPlanName: string;
  status: string;
  expiryDate: string;
  expiryDateFormatted: string;
  triggerDate: string;
  triggerDateFormatted: string;
  amount: string;
  wagerRequirement: string;
  amountWagered: string;
}

export interface IRedeemBonusViewModel {
  status: string;
  bonus: IRedeemBonusResponseBonus;
}

export interface IForfeitBonusViewModel {
  errors: string[];
  status: string;
  message: string;
  advices: string[];
}

export interface IBonusWalletResponsePayload {
  items: IBonus[];
}

export interface IEligibleOptInBonusPlansState {
  data: IEligibleOptInBonusPlan[] | null;
  pending: boolean;
  status: IStatus | null;
}

export interface IDepositEligibleBonusPlansState {
  data: IDepositEligibleBonusPlan[] | null;
  pending: boolean;
  status: IStatus | null;
}

export interface IRedeemBonusState {
  pending: boolean;
  status: IStatus | null;
}

export interface IDepositEligibleBonusPlansRequestPayload {
  depositAmount: number;
  paymentMethod: string;
}