import { userSelector } from ".";
import { IGameSessionsViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { GameSessionsState } from "@/fe-core/meta/types/user";

export const gameSessionsSelector = (state: RootState): GameSessionsState => {
  const { gameSessions } = userSelector(state);

  return gameSessions;
};

export const gameSessionsDataSelector = (
  state: RootState
): IGameSessionsViewModel | null => {
  const { data } = gameSessionsSelector(state);

  return data;
};
