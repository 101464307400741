import { cashierSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { ITransactionHistoryViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { TransactionHistoryState } from "@/fe-core/meta/types/user";

export const transactionHistorySelector = (
  state: RootState
): TransactionHistoryState => {
  const { transactionHistory } = cashierSelector(state);

  return transactionHistory;
};

export const transactionHistoryPendingSelector = (
  state: RootState
): boolean => {
  const { pending } = transactionHistorySelector(state);

  return pending;
};

export const transactionHistoryDataSelector = (
  state: RootState
): ITransactionHistoryViewModel | null => {
  const { data } = transactionHistorySelector(state);

  return data;
};

export const transactionHistoryStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = transactionHistorySelector(state);

  return status;
};
