import { userSelector } from ".";
import { IBettingHistoryViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { BettingHistoryState } from "@/fe-core/meta/types/user";

export const bettingHistorySelector = (
  state: RootState
): BettingHistoryState => {
  const { bettingHistory } = userSelector(state);

  return bettingHistory;
};

export const bettingHistoryDataSelector = (
  state: RootState
): IBettingHistoryViewModel | null => {
  const { data } = bettingHistorySelector(state);

  return data;
};
