import { validateSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IValidateMobileViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { ValidateMobileState } from "@/fe-core/meta/types/user";

export const validateMobileSelector = (
  state: RootState
): ValidateMobileState => {
  const { mobile } = validateSelector(state);

  return mobile;
};

export const validateMobilePendingSelector = (state: RootState): boolean => {
  const { pending } = validateMobileSelector(state);

  return pending;
};

export const validateMobileDataSelector = (
  state: RootState
): IValidateMobileViewModel | null => {
  const { data } = validateMobileSelector(state);

  return data;
};

export const validateMobileStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = validateMobileSelector(state);

  return status;
};
