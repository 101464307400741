import { cashierSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IWithdrawMethod, IWithdrawViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { PrepareWithdrawCashierStatus, WithdrawState } from "@/fe-core/meta/types/user";

export const withdrawSelector = (state: RootState): WithdrawState => {
  const { withdraw } = cashierSelector(state);

  return withdraw;
};

export const withdrawPendingSelector = (state: RootState): boolean => {
  const { pending } = withdrawSelector(state);

  return pending;
};

export const withdrawMethodsSelector = (state: RootState): IWithdrawMethod[] | [] => {
  const { withdrawMethods } = withdrawSelector(state);
  return withdrawMethods?.withdrawalMethods ?? [];
};

export const withdrawMethodsStatusSelector = (state: RootState): IStatus | null => {
  const { status } = withdrawSelector(state);
  return status;
};

export const pendingWithdrawMethodsSelector = (state: RootState): boolean => {
  const { pending } = withdrawSelector(state);
  return pending;
};

export const selectedPaymentMethodSelector = (
  state: RootState
): IWithdrawMethod | null => {
  const { selectedPaymentMethod } = withdrawSelector(state);
  return selectedPaymentMethod;
};

export const processWithdrawPendingSelector = (state: RootState): boolean => {
  const { processWithdrawPending } = withdrawSelector(state);
  return processWithdrawPending;
};

export const processWithdrawStatusSelector = (
  state: RootState
): IStatus | null | PrepareWithdrawCashierStatus => {
  const { processWithdrawStatus } = withdrawSelector(state);
  return processWithdrawStatus;
};


export const withdrawDataSelector = (
  state: RootState
): IWithdrawViewModel | null => {
  const { data } = withdrawSelector(state);

  return data;
};

export const withdrawStatusSelector = (state: RootState): IStatus | null => {
  const { status } = withdrawSelector(state);

  return status;
};
