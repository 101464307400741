import { registerSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { ISignupLocalPayload } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { IUsernameCheck, signupScreens, SignupState } from '@/fe-core/meta/types/user';

export const signupSelector = (state: RootState): SignupState => {
  const { signup } = registerSelector(state);

  return signup;
};

export const signupActiveModalSelector = (state: RootState): boolean => {
  const { showSignup } = signupSelector(state);

  return showSignup;
};

export const signupActiveScreenSelector = (state: RootState): signupScreens => {
  const { activeScreen } = signupSelector(state);

  return activeScreen;
};

export const signupPayloadSelector = (state: RootState): ISignupLocalPayload => {
  const { signUpPayload } = signupSelector(state);

  return signUpPayload;
};

export const signupPendingSelector = (state: RootState): boolean => {
  const { pending } = signupSelector(state);

  return pending;
};

export const signupStatusSelector = (state: RootState): IStatus | null => {
  const { status } = signupSelector(state);

  return status;
};

export const usernameCheckSelector = (state: RootState): IUsernameCheck => {
  const { usernameCheck } = signupSelector(state);

  return usernameCheck;
};

export const checkEmailCredentialSelector = (state: RootState): IUsernameCheck => {
  const { checkEmailCredential } = signupSelector(state);

  return checkEmailCredential;
};

export const checkMobileCredentialSelector = (state: RootState): IUsernameCheck => {
  const { checkMobileCredential } = signupSelector(state);

  return checkMobileCredential;
};

export const signupUsernameCheckPendingSelector = (state: RootState): boolean => {
  const { pending } = usernameCheckSelector(state);

  return pending;
};

export const signupUsernameCheckStatusSelector = (state: RootState): IStatus | null => {
  const { status } = usernameCheckSelector(state);

  return status;
};

export const checkEmailCredentialPendingSelector = (state: RootState): boolean => {
  const { pending } = checkEmailCredentialSelector(state);

  return pending;
};

export const checkEmailCredentialStatusSelector = (state: RootState): IStatus | null => {
  const { status } = checkEmailCredentialSelector(state);

  return status;
};

export const checkMobileCredentialPendingSelector = (state: RootState): boolean => {
  const { pending } = checkMobileCredentialSelector(state);

  return pending;
};

export const checkMobileCredentialStatusSelector = (state: RootState): IStatus | null => {
  const { status } = checkMobileCredentialSelector(state);

  return status;
};
