import { registerSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { RootState } from "@/fe-core/meta/types/root";
import { SignupEmailConfirmationState } from "@/fe-core/meta/types/user";

export const signupEmailConfirmationSelector = (
  state: RootState
): SignupEmailConfirmationState => {
  const { signupEmailConfirmation } = registerSelector(state);

  return signupEmailConfirmation;
};

export const signupEmailConfirmationPendingSelector = (
  state: RootState
): boolean => {
  const { pending } = signupEmailConfirmationSelector(state);

  return pending;
};

export const signupEmailConfirmationStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = signupEmailConfirmationSelector(state);

  return status;
};

export const signupEmailResendSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { resend } = signupEmailConfirmationSelector(state);

  return resend;
};

export const signupResendEmailStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = signupEmailResendSelector(state);

  return status;
}
  ;
export const signupResendEmailPendingSelector = (
  state: RootState
): boolean | null => {
  const { pending } = signupEmailResendSelector(state);

  return pending;
};