import { registerSelector } from ".";
import { IConsentItem } from "@/fe-core/meta/interfaces/user/register/consentRequirements";
import { RootState } from "@/fe-core/meta/types/root";
import { UserConsentsState } from "@/fe-core/meta/types/user";

export const mainConsentRequirementsSelector = (
  state: RootState
): UserConsentsState => {
  const { userConsents } = registerSelector(state);
  return userConsents;
};

export const isPendingConsentSelector = (state: RootState): boolean => {
  const { pending } = mainConsentRequirementsSelector(state);
  return pending;
};

export const consentRequirementsSelector = (
  state: RootState
): IConsentItem[] | null => {
  const { userConsents } = mainConsentRequirementsSelector(state);

  return userConsents;
};

export const registrationConsentRequirementsSelector = (
  state: RootState
): IConsentItem[] | null => {
  const { userConsents } = mainConsentRequirementsSelector(state);
  const registrationConsents =
    //@ts-ignore
    userConsents && userConsents?.filter((x) => x.showOnRegister);
  return registrationConsents;
};
