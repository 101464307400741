import { FC } from "react";

import currency from "/public/img/icons/currency.svg";
import emailSent from "/public/img/icons/email-sent.svg";
import eye from "/public/img/icons/eye.svg";
import blind from "/public/img/icons/blind.svg";
import circlevalid from "/public/img/icons/circle-valid.svg";
import circleerror from "/public/img/icons/circle-error.svg";
import circleInfo from "/public/img/icons/circle-info.svg";

import transactions from "/public/img/icons/accounts/transactions.svg";
import livesupport from "/public/img/icons/accounts/live-support.svg";
import wallet from "/public/img/icons/accounts/wallet.svg";
import deposit from "/public/img/icons/accounts/deposit.svg";
import withdraw from "/public/img/icons/accounts/withdraw.svg";
import user from "/public/img/icons/accounts/user.svg";
import reward from "/public/img/icons/accounts/reward.svg";
import kyc from "/public/img/icons/accounts/kyc.svg";
import converter from "/public/img/icons/accounts/converter.svg";
import pool from "/public/img/icons/accounts/pool.svg";
import verify from "/public/img/icons/accounts/verify.svg";
import verified from "/public/img/icons/accounts/verified.svg";
import angleArrow from "/public/img/icons/angle-arrow.svg";
import exclamationTriangle from "/public/img/icons/exclamation-triangle.svg";
import trash from "/public/img/icons/delete.svg";
import hamburger from "/public/img/icons/menu.svg";
import home from "/public/img/icons/home.svg";
import close from "/public/img/icons/close.svg";
import edit from "/public/img/icons/edit.svg";
import clock from "/public/img/icons/clock.svg";
import window from "/public/img/icons/window.svg";
import fullscreen from "/public/img/icons/full-screen.svg";

import casino from "/public/img/icons/casino.svg";
import casual from "/public/img/icons/casual.svg";
import liveCasino from "/public/img/icons/live-casino.svg";
import live from "/public/img/icons/live.svg";
import sports from "/public/img/icons/sports.svg";
import promotion from "/public/img/icons/promotions.svg";
import liveStream from "/public/img/icons/live-stream.svg";

import google from "/public/img/icons/google.svg";
import facebook from "/public/img/icons/facebook.svg";
import apple from "/public/img/icons/apple.svg";
import yahoo from "/public/img/icons/yahoo.svg";
import line from "/public/img/icons/line.svg";
import calendar from "/public/img/icons/calendar.svg";
import search from "/public/img/icons/search.svg";
import dollar from "/public/img/icons/dollar.svg";
import light from "/public/img/icons/light.svg";
import dark from "/public/img/icons/dark.svg";
import chat from "/public/img/icons/chat.svg";
import videoChat from "/public/img/icons/video-chat.svg";
import globe from "/public/img/icons/globe.svg";
import like from "/public/img/icons/like.svg";
import star from "/public/img/icons/star.svg";
import staroutlined from "/public/img/icons/star-outlined.svg";
import shareoutlined from "/public/img/icons/share-outlined.svg";
import menu from "/public/img/icons/menu.svg";
import emoji from "/public/img/icons/emoji.svg";
import report from "/public/img/icons/report.svg";
import nodata from "/public/img/icons/no-data.svg";
import instagram from "/public/img/icons/instagram.svg";
import xtwitter from "/public/img/icons/x-twitter.svg";
import twitter from "/public/img/icons/twitter.svg";
import telegram from "/public/img/icons/telegram.svg";
import hidden from "/public/img/icons/hidden.svg";
import spinner from "/public/img/icons/spinner.svg";
import history from "/public/img/icons/history.svg";
import logout from "/public/img/icons/logout.svg";
import phone from "/public/img/icons/phone.svg";
import desktop from "/public/img/icons/desktop.svg";
import trophy from "/public/img/icons/trophy.svg";
import wagered from "/public/img/icons/wagered.svg";
import liveSports from "/public/img/icons/live-sports.svg";

import email from "/public/img/icons/email.svg";
import casinolobby from "/public/img/icons/casino/casino-lobby.svg";
import favorites from "/public/img/icons/casino/favorites.svg";
import recentGames from "/public/img/icons/casino/recent-games.svg";
import heart from "/public/img/icons/heart.svg";
import heartoutline from "/public/img/icons/heart-outline.svg";
import settings from "/public/img/icons/settings.svg";
import timeroutline from "/public/img/icons/timer-outline.svg";
import dealer from "/public/img/icons/dealer.svg";
import list from "/public/img/icons/list.svg";
import IOSShare from "/public/img/icons/ios-share.svg";
import dollaroutline from "/public/img/icons/dollar-outline.svg";
import min from "/public/img/icons/min.svg";
import exclamation from "/public/img/icons/i-icon.svg";
import news from "/public/img/icons/news.svg";

interface Iicons {
  [name: string]: { src: string };
}

const icons: Iicons = {
  emailSent,
  eye,
  blind,
  "circle-valid": circlevalid,
  "circle-error": circleerror,
  "circle-info": circleInfo,
  "exclamation-triangle": exclamationTriangle,
  transactions,
  casual,
  livesupport,
  wallet,
  list,
  deposit,
  withdraw,
  user,
  reward,
  converter,
  pool,
  verify,
  verified,
  angleArrow,
  hamburger,
  home,
  close,
  clock,
  edit,
  liveSports,
  delete: trash,
  window,
  fullscreen,
  casino,
  liveCasino,
  live,
  promotion,
  sports,
  liveStream,
  google,
  facebook,
  apple,
  yahoo,
  line,
  calendar,
  search,
  dollar,
  light,
  dark,
  chat,
  videoChat,
  globe,
  like,
  star,
  staroutlined,
  shareoutlined,
  menu,
  emoji,
  report,
  nodata,
  min,
  instagram,
  xtwitter,
  twitter,
  telegram,
  hidden,
  spinner,
  history,
  logout,
  phone,
  desktop,
  trophy,
  wagered,
  IOSShare,
  email,
  "casino-lobby": casinolobby,
  favorites: favorites,
  "recent-games": recentGames,
  heart: heart,
  "heart-outline": heartoutline,
  settings,
  "timer-outline": timeroutline,
  dealer,
  "dollar-outline": dollaroutline,
  currency,
  kyc,
  exclamation,
  news
};

type IconProps = {
  height?: number | string | undefined;
  width?: number | string | undefined;
  className?: string;
  icon: string; // Ensure icon is a key of the icons object
};

const Index: React.FC<IconProps> = ({
  height,
  width,
  className = "",
  icon,
}) => {
  const iconUrl = icons?.[icon]?.src ?? icon;
  return (
    <svg width={width} height={height} className={`fill-current ${className}`}>
      <use xlinkHref={`${iconUrl}#root`} />
    </svg>
  );
};

export default Index;
