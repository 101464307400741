import { RootState } from "@/fe-core/meta/types/root";
import { UserState } from "@/fe-core/meta/types/user";

export const userSelector = ({ user }: RootState): UserState => user;

export * from "./profileSelectors";
export * from "./balanceSelectors";
export * from "./consentsSelectors";
export * from "./limitsSelectors";
export * from "./registerSelectors";
export * from "./rolesSelectors";
export * from "./bettingHistorySelectors";
export * from "./favoritesSelectors";
export * from "./cashierSelectors";
export * from "./selfExclusionSelectors";
export * from "./documentsSelectors";
export * from "./passwordSelectors";
export * from "./regionsSelectors";
export * from "./restrictionsSelectors";
export * from "./recentPlayedSelectors";
export * from "./balanceWarningSelectors";
export * from "./affiliateSelectors";
export * from "./gameSessionsSelectors";
