import Cookies from "js-cookie";
import ExpiredStorage from "expired-storage";
import { timeOuts } from "@/config/general";

export const SessionStorage = {
  setItem: (
    key: string,
    value: string
  ): void => {
    window.sessionStorage.setItem(key, value);
  },

  getItem: (key: string): string | undefined => {
    return  window.sessionStorage.getItem(key);
  },

  removeItem: (key: string): void => {
    window.sessionStorage.removeItem(key);
  },

  clear: (): boolean => {
    return true;
  },
};
export const CookiesStorage = {
  setItem: (
    key: string,
    value: string,
    options?: { [key: string]: string | number }
  ): void => {
    Cookies.set(key, value, options);
  },

  getItem: (key: string): string | undefined => {
    return Cookies.get(key);
  },

  removeItem: (key: string): void => {
    Cookies.remove(key);
  },

  clear: (): boolean => {
    return true;
  },
};

let expiredStorage: ExpiredStorage;

if (typeof window !== "undefined") {
  expiredStorage = new ExpiredStorage(window.localStorage);
} else {
  expiredStorage = new ExpiredStorage(CookiesStorage);
}

const Storage = {
  set: (
    key: string,
    value: string,
    ttl: number = timeOuts.DEFAULT_STORAGE_TIMEOUT
  ): void => {
    expiredStorage.setItem(key, value, ttl);
  },

  get: (key: string): string | number | null => {
    return expiredStorage.getItem(key);
  },

  remove: (key: string): void => {
    expiredStorage.removeItem(key);
  },
};

export default Storage;
