import { IRoleViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { RolesState } from "@/fe-core/meta/types/user";
import { userSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";

export const userRolesSelector = (state: RootState): RolesState => {
  const { roles } = userSelector(state);

  return roles;
};

export const userRolesDataSelector = (
  state: RootState
): IRoleViewModel[] | null => {
  const { data } = userRolesSelector(state);

  return data;
};

export const userRolesPendingSelector = (state: RootState): boolean => {
  const { pending } = userRolesSelector(state);

  return pending;
};

export const userRolesStatusSelector = (state: RootState): IStatus | null => {
  const { status } = userRolesSelector(state);
  return status;
};

export const hasAuditRole = (state: RootState): boolean => {
  const { data } = userRolesSelector(state);

  if (data && data.length > 1) {
    const hasAudit = data.some((role) => role.roleName === "Audit");

    return hasAudit;
  }

  return false;
};

export const hasVipRole = (state: RootState): boolean => {
  const { data } = userRolesSelector(state);

  if (data && data.length > 1) {
    const hasVip = data.some(
      (role) =>
        role.roleName === "VIP GOLD" ||
        role.roleName === "VIP BASIC" ||
        role.roleName === "New VIP"
    );
    return hasVip;
  }

  return false;
};

export const hasWithdrawalBlockSelector = (state: RootState): boolean => {
  const { data } = userRolesSelector(state);

  const KYC_REQUIRED_WITHDRAWAL_ROLE_LIST =
    process.env.NEXT_PUBLIC_KYC_REQUIRED_WITHDRAWAL_ROLE_LIST?.split(",") ?? [];
  if (data && data.length > 0) {
    const hasWithdrawalBlock = data.some(
      (role) =>
        KYC_REQUIRED_WITHDRAWAL_ROLE_LIST &&
        KYC_REQUIRED_WITHDRAWAL_ROLE_LIST.includes(role.roleName)
    );
    return hasWithdrawalBlock;
  }
  return false;
};

export const hasDepositBlockSelector = (state: RootState): boolean => {
  const { data } = userRolesSelector(state);

  const KYC_REQUIRED_DEPOSIT_ROLE_LIST =
    process.env.NEXT_PUBLIC_KYC_REQUIRED_DEPOSIT_ROLE_LIST?.split(",") ?? [];
  if (data && data.length > 0) {
    const hasDepositBlock = data.some(
      (role) =>
        KYC_REQUIRED_DEPOSIT_ROLE_LIST &&
        KYC_REQUIRED_DEPOSIT_ROLE_LIST.includes(role.roleName)
    );
    return hasDepositBlock;
  }
  return false;
};
