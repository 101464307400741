import { RootState } from "@/fe-core/meta/types/root";
import { userSelector } from "./index";
import { RegionsState } from "@/fe-core/meta/types/user/regions";
import { IRegions } from "@/fe-core/meta/interfaces/user/regions";

export const userRegionsSelector = (state: RootState): RegionsState => {
  const { regions } = userSelector(state);

  return regions;
};

export const userRegionsDataSelector = (
  state: RootState
): { regions: IRegions[] } | null => {
  const { data } = userRegionsSelector(state);

  return data;
};

export const userRegionsArraySelector = (
  state: RootState
): IRegions[] | null => {
  const data = userRegionsDataSelector(state);

  const regions = data ? data.regions : null;

  return regions;
};
