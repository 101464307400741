import { kycStatuses } from "@/fe-core/meta/interfaces/user";

export enum verificationsStatus {
  UNKNOWN = 0,
  PENDING = 1,
  ERROR = 2,
  SUCCESS = 3,
  FAILURE = 4,
}

export enum documentsStatuses {
  REQUESTED = 1,
  UPLOADED = 2,
  DELETED = 3,
  EXPIRED = 4,
  VALID = 5,
  INVALID = 6,
}

export const verificationsColors: { [key: string]: string } = {
  UNKNOWN: 'inherit',
  PENDING: 'inherit',
  ERROR: 'inherit',
  SUCCESS: '#30cf97',
  FAILURE: 'inherit',
}

export const verificationsIconURL: { [key: string]: string } = {
  UNKNOWN: `url('/img/icons/exclamation-triangle-orange.svg')`,
  PENDING: `url('/img/icons/exclamation-triangle-orange.svg')`,
  ERROR: `url('/img/icons/exclamation-triangle.svg')`,
  SUCCESS: `url('/img/icons/circle-check.svg')`,
  FAILURE: `url('/img/icons/exclamation-triangle.svg')`,
}

export const verificationsTypes: { [key: string]: Object } = {
  Age: { label:"Identity Verification", translationId:"identity-verification"},
  Documents: { label:"Identity Card", translationId:"identity-card-verification"},
  ProofOfAddress: { label:"Address Verification", translationId:"address-verification"},
  ProofOfPayment: { label:"Payment Method Verification", translationId:"payment-method-verification"},
  SourceOfWealth: { label:"Other Documents", translationId:"other-documents-verification"},
}

export const documentsTypes: object[] = [
  { label:"Unknown Document",translationId:"unknown-document" },
  { label:"Passport", translationId:"passport" },
  { label:"ID card (front)", translationId:"id-card-front" },
  { label:"ID card (back)", translationId:"id-card-back" },
  { label:"Birth certificate", translationId:"birth-certificate" },
  { label:"Driver's License (front)", translationId:"drivers-license-front" },
  { label:"Driver's License (back)", translationId:"drivers-license-back" },
  { label:"Residence Permit", translationId:"residence-permit" },
  { label:"Credit/Debit Card (front)", translationId:"credit-debit-card-front" },
  { label:"Credit/Debit Card (back)", translationId:"credit-debit-card-back" },
  { label:"Proof of Address", translationId:"proof-of-address" },
  { label:"Utility Bill", translationId:"utility-bill" },
  { label:"Tax Bill", translationId:"tax-bill" },
  { label:"Certified Copy", translationId:"certified-copy" },
  { label:"Selfie",translationId:"selfie" },
  { label:"Account Statement", translationId:"account-statement" },
  { label:"Lease Agreement", translationId:"lease-agreement" },
  { label:"Insurance Contract", translationId:"insurance-contract" },
  { label:"Other", translationId:"other" },
  { label:"Employment and/or Savings Income", translationId:"employment-and-savings-income" },
  { label:"Sales or Stocks or Other Investments", translationId:"sales-or-stocks-or-other-investments" },
  { label:"Company Dividends/Profit", translationId:"company-dividends-profit" },
  { label:"Company Sale", translationId:"company-sale" },
  { label:"Individual Goods/Company Payments", translationId:"individual-goods-company-payments" },
  { label:"Property Sales", translationId:"property-sales" },
  { label:"Inheritance", translationId:"inheritance" },
  { label:"Life Insurance Maturity or Surrender", translationId:"life-insurance-maturity-or-surrender" },
  { label:"Insurance Income", translationId:"insurance-income" },
  { label:"Compensation Payments", translationId:"compensation-payments" },
  { label:"Lottery/Gambling/Casino Winnings", translationId:"lottery-gambling-casino-winnings" },
  { label:"Loan", translationId:"loan" },
  { label:"Gift", translationId:"gift" },
  { label:"eWallet", translationId:"ewallet" },
  { label:"Voucher", translationId:"voucher" },
]

export const allowDocumentsUploadStatuses = [
  kycStatuses.OPEN,
  kycStatuses.REQUESTED,
  kycStatuses.PENDING,
];