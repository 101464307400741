import { storageKeys } from "@/config/general";
import { CookiesStorage } from "@/fe-core/helpers/storage";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IBankHistory } from "@/fe-core/meta/interfaces/user/cashier/bankingHistory";
import { RootState } from "@/fe-core/meta/types/root";
import {
  BankingHistoryState,
  bankingHistoryTypes,
} from "@/fe-core/meta/types/user";
import { cashierSelector } from ".";
import {
  decryptedStringDataCookie,
  encryptedStringData,
} from "./../../../../helpers/general";

export const bankingHistorySelector = (
  state: RootState
): BankingHistoryState => {
  const { bankingHistory } = cashierSelector(state);

  return bankingHistory;
};

export const bankingHistoryPendingSelector = (state: RootState): boolean => {
  const { pending } = bankingHistorySelector(state);

  return pending;
};

export const bankingHistoryDataSelector = (
  state: RootState
): IBankHistory[] | null => {
  const { data } = bankingHistorySelector(state);

  return data;
};

export const bankingHistoryStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = bankingHistorySelector(state);

  return status;
};

export const lastDepositAmountSelector = (state: RootState): number | null => {
  const histories = bankingHistoryDataSelector(state);
  const lastDepositAmount = histories?.find(
    (history) => history?.type === bankingHistoryTypes.DEPOSIT
  )?.amount;

  if (lastDepositAmount && storageKeys.LAST_DEPOSIT_AMOUNT) {
    CookiesStorage.setItem(
      storageKeys.LAST_DEPOSIT_AMOUNT,
      encryptedStringData(lastDepositAmount?.toString())
    );
  }

  const storedValue = storageKeys.LAST_DEPOSIT_AMOUNT
    ? decryptedStringDataCookie(storageKeys.LAST_DEPOSIT_AMOUNT)
    : null;

  const storedAmount =
    storedValue && !isNaN(parseFloat(storedValue))
      ? parseFloat(storedValue)
      : null;

  return storedAmount
    ? storedAmount
    : lastDepositAmount
    ? parseFloat(lastDepositAmount)
    : null;
};

export const lastWithdrawAmountSelector = (state: RootState): number | null => {
  const histories = bankingHistoryDataSelector(state);
  const lastWithdrawalAmount = histories?.find(
    (history) => history?.type === bankingHistoryTypes.WITHDRAWAL
  )?.amount;

  return lastWithdrawalAmount ? parseFloat(lastWithdrawalAmount) : null;
};
