import { userSelector } from ".";
import { ILimit, ILimitsViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { LimitsState } from "@/fe-core/meta/types/user/limits";

export const userLimitsSelector = (state: RootState): LimitsState => {
  const { limits } = userSelector(state);

  return limits;
};

export const userLimitsDataSelector = (
  state: RootState
): ILimitsViewModel | null => {
  const { data } = userLimitsSelector(state);

  return data;
};

export const userDepositLimitsSelector = (
  state: RootState
): ILimit[] | undefined => {
  //@ts-ignore
  const { depositPerDay, depositPerWeek, depositPerMonth } =
    userLimitsDataSelector(state) || {};

  let depositLimits: ILimit[] = [];
  if (depositPerDay && depositPerWeek && depositPerMonth) {
    depositLimits = [depositPerDay, depositPerWeek, depositPerMonth];
  }

  return depositLimits;
};

export const userDepositDailyLimitSelector = (
  state: RootState
): ILimit | undefined => {
  //@ts-ignore
  const { depositPerDay } = userLimitsDataSelector(state) || {};

  return depositPerDay;
};

export const userDepositWeeklyLimitSelector = (
  state: RootState
): ILimit | undefined => {
  //@ts-ignore
  const { depositPerWeek } = userLimitsDataSelector(state) || {};

  return depositPerWeek;
};

export const userDepositMonthlyLimitSelector = (
  state: RootState
): ILimit | undefined => {
  const { depositPerMonth } = userLimitsDataSelector(state) || {};

  return depositPerMonth;
};

export const userLossLimitSelector = (state: RootState): ILimit | undefined => {
  const { loss } = userLimitsDataSelector(state) || {};

  return loss;
};

export const userLimitsPendingSelector = (state: RootState): boolean => {
  const { pending } = userLimitsSelector(state);
  return pending;
};

export const userGameLimitPendingSelector = (
  state: RootState
): { [key: string]: boolean } => {
  const { limitsPending } = userLimitsSelector(state);
  return limitsPending;
};
