import { ISelfExclusionViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { SelfExclusionState } from "@/fe-core/meta/types/user";
import { userSelector } from ".";

export const selfExclusionSelector = (state: RootState): SelfExclusionState => {
  const { selfExclusion } = userSelector(state);

  return selfExclusion;
};

export const selfExclusionDataSelector = (
  state: RootState
): ISelfExclusionViewModel | null => {
  const { data } = selfExclusionSelector(state);

  return data;
};

export const selfExclusionPendingSelector = (state: RootState): boolean => {
  const { pending } = selfExclusionSelector(state);

  return pending;
};
