import { registerSelector } from "..";
import { RootState } from "@/fe-core/meta/types/root";
import { ValidateState } from "@/fe-core/meta/types/user";

export const validateSelector = (state: RootState): ValidateState => {
  const { validate } = registerSelector(state);

  return validate;
};

export * from "./cnpSelectors";
export * from "./usernameSelectors";
export * from "./emailSelectors";
export * from "./mobileSelectors";
