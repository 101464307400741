import { validateSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IValidateEmailViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { ValidateEmailState } from "@/fe-core/meta/types/user";

export const validateEmailSelector = (state: RootState): ValidateEmailState => {
  const { email } = validateSelector(state);

  return email;
};

export const validateEmailPendingSelector = (state: RootState): boolean => {
  const { pending } = validateEmailSelector(state);

  return pending;
};

export const validateEmailDataSelector = (
  state: RootState
): IValidateEmailViewModel | null => {
  const { data } = validateEmailSelector(state);

  return data;
};

export const validateEmailStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = validateEmailSelector(state);

  return status;
};
