import Storage from "@/fe-core/helpers/storage";
import { ISignupPayload } from "@/fe-core/meta/interfaces/user";
import dayjs from "dayjs";
import { storageKeys } from "./general";

export const initialState: ISignupPayload = {
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  mobile: {
    prefix: "",
    number: "",
  },
  firstName: "",
  lastName: "",
  address: "",
  birthDate: "",  
  city: "",
  postalCode: "",
  userConsents: {},
  currency: "",
  language: "",
  country: "",
  consentedVersions: "",
  province: "",
  referralId: Storage.get(storageKeys.AFFILIATE_MARKER) as string,
  mobileNumber: ""
}

export const isUser18 = (birthdate: string): boolean => {
  const today = dayjs();
  const birthDate = dayjs(birthdate);

  const age = today.diff(birthDate, 'year');

  return age >= 18;
}