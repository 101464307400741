import { userSelector } from "..";
import { RootState } from "@/fe-core/meta/types/root";
import { RegisterState } from "@/fe-core/meta/types/user";

export const registerSelector = (state: RootState): RegisterState => {
  const { register } = userSelector(state);

  return register;
};

export * from "./signupSelectors";
export * from "./signupSmsSelectors";
export * from "./signupEmailConfirmationSelectors";
export * from "./validateSelectors";
export * from "./consentRequirements";
