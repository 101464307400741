import { RootState } from "@/fe-core/meta/types/root";
import {
  RestrictionsState,
  RestrictionsDetailState,
} from "@/fe-core/meta/types/user";
import { userSelector } from ".";

export const restrictionsSelector = (state: RootState): RestrictionsState => {
  const { restrictions } = userSelector(state);

  return restrictions;
};

export const restrictionsDetailSelector = (
  state: RootState
): RestrictionsDetailState => {
  const {
    restrictions: {
      detail: { userId, sessionId, phone, phonePrefix },
    },
  } = userSelector(state);

  return { userId, sessionId, phone, phonePrefix };
};


export const restrictionsPendingSelector = (state: RootState): boolean => {
  const { pending } = restrictionsSelector(state);

  return pending;
};