import {
  IFieldConfigView,
  IProfileUpdateNeedsAction,
  ITriggeredCurrencyObject,
} from "@/fe-core/meta/interfaces/user";

export const PLACEHOLDER_ALIAS = "";
export const PLACEHOLDER_FIRST_NAME = "FIRSTNAME";
export const PLACEHOLDER_LAST_NAME = "LASTNAME";
export const PLACEHOLDER_ADDRESS = "ADDRESS1";
export const PLACEHOLDER_CITY = "CITY";
export const PLACEHOLDER_BIRTH = "2000-11-11T00:00:00";
export const PLACEHOLDER_MOBILE_PHONE = "123456";
export const PLACEHOLDER_MOBILE_PREFIX = "+86";

export const GAME_PLAY_ACTION = "GAME_PLAY";
export const DEPOSIT_ACTION = "DEPOSIT";
export const DEPOSIT_AMOUNT_PROFILE_UPDATE_TRIGGER: ITriggeredCurrencyObject = {
  USD: 100,
  JPY: 100,
};
export const WITHDRAWAL_ACTION = "WITHDRAWAL";
export const WITHDRAWAL_AMOUNT_PROFILE_UPDATE_TRIGGER: ITriggeredCurrencyObject =
{
  USD: 50,
  JPY: 50,
};
export const LOGIN_ACTION = "LOGIN";
export const USERNAME_TEST_REGEX = /^[a-zA-Z0-9_]{4,20}$/;
export const ADDRESS_TEST_REGEX = /^[a-zA-Z0-9\s,.\-']+$/;
export const CITY_PROVINCE_TEST_REGEX = /^[a-zA-Z\s]+$/
export const POSTAL_CODE_TEST_REGEX = /^[a-zA-Z0-9\s-]+$/

export const PROFILE_UPDATE_NEEDS_ACTION: IProfileUpdateNeedsAction = {
  [GAME_PLAY_ACTION]: !!(
    process.env.NEXT_PUBLIC_IS_PROFILE_UPDATE_NEEDS_ON_GAME_PLAY_ACTION ===
    "YES"
  ),
  [DEPOSIT_ACTION]: !!(
    process.env.NEXT_PUBLIC_IS_PROFILE_UPDATE_NEEDS_ON_DEPOSIT_ACTION === "YES"
  ),
  [WITHDRAWAL_ACTION]: !!(
    process.env.NEXT_PUBLIC_IS_PROFILE_UPDATE_NEEDS_ON_WITHDRAWAL_ACTION ===
    "YES"
  ),
  [LOGIN_ACTION]: !!(
    process.env.NEXT_PUBLIC_IS_PROFILE_UPDATE_NEEDS_ON_LOGIN_ACTION === "YES"
  ),
};

export const FIELDS_CONFIG: { [key: string]: IFieldConfigView } = {
  alias: {
    registration: false,
    profileUpdate: true,
  },
  firstName: {
    registration: false,
    profileUpdate: true,
  },
  lastName: {
    registration: false,
    profileUpdate: true,
  },
  mobilePhone: {
    registration: false,
    profileUpdate: true,
  },
  email: {
    registration: true,
    profileUpdate: false,
  },
  password: {
    registration: true,
    profileUpdate: false,
  },
  birthDate: {
    registration: false,
    profileUpdate: true,
  },
  // postalCode: {
  //   registration: false,
  //   profileUpdate: true,
  // },
  city: {
    registration: false,
    profileUpdate: true,
  },
  address: {
    registration: false,
    profileUpdate: true,
  },
  // region: {
  //   registration: false,
  //   profileUpdate: false,
  // },
  gender: {
    registration: true,
    profileUpdate: false,
  },
};

export const isActionNeedProfileUpdate = (
  action: string,
  actionNeedCheck: () => void,
  actionNotNeedCheck: () => void,
  props?: any
) => {

  if (action === DEPOSIT_ACTION && PROFILE_UPDATE_NEEDS_ACTION[action]) {
    if (props?.sum > DEPOSIT_AMOUNT_PROFILE_UPDATE_TRIGGER[props?.currency]) {
      actionNeedCheck();
    } else {
      actionNotNeedCheck();
    }
  } else if (
    action === WITHDRAWAL_ACTION &&
    PROFILE_UPDATE_NEEDS_ACTION[action]
  ) {
    if (
      props?.sum > WITHDRAWAL_AMOUNT_PROFILE_UPDATE_TRIGGER[props?.currency]
    ) {
      actionNeedCheck();
    } else {
      actionNotNeedCheck();
    }
  } else if (PROFILE_UPDATE_NEEDS_ACTION[action]) {
    actionNeedCheck();
  } else {
    actionNotNeedCheck();
  }
};
