import { cashierSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IDepositBonusesViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { DepositBonusesState } from "@/fe-core/meta/types/user";

export const depositBonusesSelector = (
  state: RootState
): DepositBonusesState => {
  const { depositBonuses } = cashierSelector(state);

  return depositBonuses;
};

export const depositBonusesPendingSelector = (state: RootState): boolean => {
  const { pending } = depositBonusesSelector(state);

  return pending;
};

export const depositBonusesDataSelector = (
  state: RootState
): IDepositBonusesViewModel | null => {
  const { data } = depositBonusesSelector(state);

  return data;
};

export const depositBonusesStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = depositBonusesSelector(state);

  return status;
};
