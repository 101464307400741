import { IStatus } from "@/fe-core/meta/interfaces/root";
import {
  IDocumentsViewModel,
  IDocumentViewModel,
  IUserDocumentViewModel,
  kycStatuses,
} from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import {
  DocumentsState,
  UploadDocumentState,
  UsersDocumentsState,
} from "@/fe-core/meta/types/user";
import { userProfileDataSelector, userSelector } from ".";
import { allowDocumentsUploadStatuses } from "@/config/documents";

export const documentsSelector = (state: RootState): DocumentsState => {
  const { documents } = userSelector(state);

  return documents;
};

export const uploadDocumentSelector = (
  state: RootState
): UploadDocumentState => {
  const { uploadDocument } = documentsSelector(state);

  return uploadDocument;
};

export const userDocumentSelector = (state: RootState): UsersDocumentsState => {
  const { userDocuments } = documentsSelector(state);

  return userDocuments;
};

export const documentsDataSelector = (
  state: RootState
): IDocumentsViewModel | null => {
  const { data } = documentsSelector(state);

  return data;
};

export const userDocumentsDataSelector = (
  state: RootState
): IUserDocumentViewModel[] | null => {
  const { data } = userDocumentSelector(state);

  return data;
};

export const documentsPendingSelector = (state: RootState): boolean => {
  const { pending } = documentsSelector(state);

  return pending;
};

export const uploadDocumentShowModalSelector = (
  state: RootState
): IDocumentViewModel | null => {
  const { selectedDocument } = documentsSelector(state);

  return selectedDocument;
};

export const uploadDocumentPendingSelector = (state: RootState): boolean => {
  const { pending } = uploadDocumentSelector(state);

  return pending;
};

export const uploadDocumentStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = uploadDocumentSelector(state);

  return status;
};

export const isUserNeedKYCDocumentsSelector = (state: RootState): boolean => {
  const { kycStatus } = userProfileDataSelector(state) || {};
  return (
    !!kycStatus &&
    allowDocumentsUploadStatuses.includes(kycStatus)
  );
};
