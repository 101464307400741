export interface IDocumentVerificationViewModel {
  documents: IDocumentViewModel[];
  status: number;
  type: string;
  url: string;
  vendorName: string;
}

export interface IDocumentViewModel {
  documentType: documentTypes;
  expiryDateRequired?: boolean | null;
  documentNumberRequired?: boolean | null;
  multipleFileRequired?: boolean | null;
  issueDateRequired?: boolean | null;
  userDocumentId?: number;
}

export interface IDocumentsViewModel extends Array<IDocumentViewModel> {}

export interface IUserDocumentFileViewModel {
  id: string;
  fileName: string;
  mimeType: string;
}

export interface IUserDocumentViewModel {
  id: number;
  documentType: documentTypes;
  issueDate: string | null;
  fileName: string;
  documentNumber: string;
  expiryDate: string | null;
  uploadDate: string | null;
  status: string;
  userDocumentFiles: IUserDocumentFileViewModel[];
  reviewDate: string | null;
}

export interface IUploadDocumentResponse {
  userDocument: IUserDocumentViewModel;
  status: string;
}

export interface IDocumentUploadUrlRequest {
  documentCode: string;
}

export interface IDocumentUploadUrlResponse {
  url: string;
}

export interface IUploadDocumentRequest {
  file: FileList;
  documentType: documentTypes;
  documentNumber?: string;
  issueDate?: string;
  expiryDate?: string;
  multipleFileRequired?: boolean;
  id?: number;
}

export interface IGetDocumentResponse {
  advices: any[];
  errors: any[];
  documentTypes: IDocumentsViewModel;
  status: string;
}
export interface IGetUserDocumentsResponse {
  userDocuments: IUserDocumentViewModel[];
  status: string;
}

export enum documentTypes {
  OTHERS = "OTHERS",
  IDENTITY_CARD = "IDENTITY_CARD",
  PROOF_OF_PAYMENT = "PROOF OF PAYMENT",
}

export enum documentStatuses {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  ON_HOLD = "ON_HOLD",
  NOT_REVIEWED = "NOT_REVIEWED",
}

export enum kycStatuses {
  PENDING = "PENDING",
  PASS = "PASS",
  REQUESTED = "REQUESTED",
  FAILED = "FAILED",
  NOT_APPLICABLE = "N/A",
  OPEN = "OPEN",
}
