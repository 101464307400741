import { registerSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { ISmsViewModel } from "@/fe-core/meta/interfaces/user/register/signupSms";
import { RootState } from "@/fe-core/meta/types/root";
import { SignupSmsState } from "@/fe-core/meta/types/user";

export const signupSmsSelector = (state: RootState): SignupSmsState => {
  const { signupSms } = registerSelector(state);

  return signupSms;
};

export const signupSmsPendingSelector = (state: RootState): boolean => {
  const {
    getSms: { pending },
  } = signupSmsSelector(state);

  return pending;
};

export const signupSmsStatusSelector = (state: RootState): IStatus | null => {
  const {
    getSms: { status },
  } = signupSmsSelector(state);

  return status;
};

export const signupSmsResendSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { resend } = signupSmsSelector(state);

  return resend;
};

export const signupSmsDataSelector = (
  state: RootState
): ISmsViewModel | null => {
  const { data } = signupSmsSelector(state);

  return data;
};
