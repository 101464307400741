import { userSelector } from ".";
import { IFavoritesViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { FavoritesState } from "@/fe-core/meta/types/user";

export const userFavoritesSelector = (state: RootState): FavoritesState => {
  const { favorites } = userSelector(state);

  return favorites;
};

export const userFavoritesDataSelector = (
  state: RootState
): IFavoritesViewModel | null => {
  const { data } = userFavoritesSelector(state);

  return data;
};

export const userFavoritesPendingSelector = (state: RootState): boolean => {
  const { pending } = userFavoritesSelector(state);

  return pending;
};
