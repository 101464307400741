import { cashierSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IDepositMethod, IDepositViewModel, IGetDepositMethodsResponse } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { DepositState } from "@/fe-core/meta/types/user";

export const depositSelector = (state: RootState): DepositState => {
  const { deposit } = cashierSelector(state);

  return deposit;
};

export const depositModalSelector = (state: RootState): boolean => {
  const { showDepositModal } = depositSelector(state);

  return showDepositModal;
};

export const depositMethodsSelector = (state: RootState): IDepositMethod[] | [] => {
  const { depositMethods } = depositSelector(state);
  return depositMethods?.depositMethods ?? [];
};

export const pendingMethodsSelector = (state: RootState): boolean => {
  const { pending } = depositSelector(state);
  return pending;
};

export const depositTempDataSelector = (state: RootState): IDepositViewModel | null => {
  const { depositTempData } = depositSelector(state);

  return depositTempData;
};

export const depositPendingSelector = (state: RootState): boolean => {
  const { pending } = depositSelector(state);

  return pending;
};

export const depositDataSelector = (
  state: RootState
): IDepositViewModel | null => {
  const { data } = depositSelector(state);

  return data;
};

export const depositStatusSelector = (state: RootState): IStatus | null => {
  const { status } = depositSelector(state);

  return status;
};
