import { userSelector } from "../";
import { ICampaignsViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { CampaignsState } from "@/fe-core/meta/types/user";

export const userCampaignsSelector = (state: RootState): CampaignsState => {
  const { campaigns } = userSelector(state);
  return campaigns;
};

export const userCampaignsDataSelector = (
  state: RootState
): ICampaignsViewModel | null => {
  const { data } = userCampaignsSelector(state);
  return data;
};

export const userCampaignsPendingSelector = (state: RootState): boolean => {
  const { pending } = userCampaignsSelector(state);
  return pending;
};
