import { cashierSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IPendingWithdrawalViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { PendingWithdrawalsState } from "@/fe-core/meta/types/user";
import { ICancelPendingWithdrawalViewModel } from '../../../../meta/interfaces/user/cashier/pendingWithdrawals';

export const pendingWithdrawalsSelector = (
  state: RootState
): PendingWithdrawalsState => {
  const { pendingWithdrawals } = cashierSelector(state);

  return pendingWithdrawals;
};

export const pendingWithdrawalsPendingSelector = (
  state: RootState
): boolean => {
  const { pending } = pendingWithdrawalsSelector(state);

  return pending;
};

export const pendingWithdrawalsDataSelector = (
  state: RootState
): IPendingWithdrawalViewModel | null => {
  const { data } = pendingWithdrawalsSelector(state);

  return data;
};

export const pendingWithdrawalsStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = pendingWithdrawalsSelector(state);

  return status;
};

export const cancelPendingWithdrawalPendingSelector = (
  state: RootState
): boolean => {
  const { cancelWithdrawalPending } = pendingWithdrawalsSelector(state);

  return cancelWithdrawalPending;
};

export const cancelPendingWithdrawalDataSelector = (
  state: RootState
): ICancelPendingWithdrawalViewModel | null => {
  const { cancelWithdrawalData } = pendingWithdrawalsSelector(state);
  return cancelWithdrawalData;
};

export const cancelPendingWithdrawalStatusSelector = (
  state: RootState
): IStatus | null => {
  const { cancelWithdrawalStatus } = pendingWithdrawalsSelector(state);

  return cancelWithdrawalStatus;
};
