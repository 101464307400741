import { validateSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IValidateUsernameViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { ValidateUsernameState } from "@/fe-core/meta/types/user";

export const validateUsernameSelector = (
  state: RootState
): ValidateUsernameState => {
  const { username } = validateSelector(state);

  return username;
};

export const validateUsernamePendingSelector = (state: RootState): boolean => {
  const { pending } = validateUsernameSelector(state);

  return pending;
};

export const validateUsernameDataSelector = (
  state: RootState
): IValidateUsernameViewModel | null => {
  const { data } = validateUsernameSelector(state);

  return data;
};

export const validateUsernameStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = validateUsernameSelector(state);

  return status;
};
