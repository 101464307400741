import { RootState } from "@/fe-core/meta/types/root";
import { BalanceWarningState } from "@/fe-core/meta/types/user";
import { userSelector } from ".";

export const balanceWarningSelector = (
  state: RootState
): BalanceWarningState => {
  const { balanceWarning } = userSelector(state);

  return balanceWarning;
};
