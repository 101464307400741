import { validateSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IValidateCnpViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { ValidateCnpState } from "@/fe-core/meta/types/user";

export const validateCnpSelector = (state: RootState): ValidateCnpState => {
  const { cnp } = validateSelector(state);

  return cnp;
};

export const validateCnpPendingSelector = (state: RootState): boolean => {
  const { pending } = validateCnpSelector(state);

  return pending;
};

export const validateCnpDataSelector = (
  state: RootState
): IValidateCnpViewModel | null => {
  const { data } = validateCnpSelector(state);

  return data;
};

export const validateCnpStatusSelector = (state: RootState): IStatus | null => {
  const { status } = validateCnpSelector(state);

  return status;
};
