import { userSelector } from "..";
import { RootState } from "@/fe-core/meta/types/root";
import { CashierState } from "@/fe-core/meta/types/user";

export const cashierSelector = (state: RootState): CashierState => {
  const { cashier } = userSelector(state);

  return cashier;
};

export * from "./depositSelectors";
export * from "./depositBonusesSelectors";
export * from "./withdrawSelectors";
export * from "./pendingWithdrawalsSelectors";
export * from "./transactionHistorySelectors";
export * from "./bankingHistorySelectors";
