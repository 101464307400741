import { userSelector } from "../";
//@todo refactor
import { IAffiliateViewModel } from "@/fe-core/meta/interfaces/user/affiliate/affiliate";
import { RootState } from "@/fe-core/meta/types/root";
import { AffiliateState } from "@/fe-core/meta/types/user";

export const userAffiliateSelector = (state: RootState): AffiliateState => {
  const { affiliate } = userSelector(state);
  return affiliate;
};

export const userAffiliateDataSelector = (
  state: RootState
): IAffiliateViewModel | null => {
  const { data } = userAffiliateSelector(state);
  return data;
};

export const userAffiliatePendingSelector = (state: RootState): boolean => {
  const { pending } = userAffiliateSelector(state);
  return pending;
};
