import { userSelector } from ".";
import { IProfileViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { ProfileState } from "@/fe-core/meta/types/user";
import { IStatus } from "@/fe-core/meta/interfaces/root";

export const userProfileSelector = (state: RootState): ProfileState => {
  const { profile } = userSelector(state);

  return profile;
};

export const userProfileDataSelector = (
  state: RootState
): IProfileViewModel | null => {
  const { data } = userProfileSelector(state);

  return data;
};

export const userProfilePendingSelector = (state: RootState): boolean => {
  const { pending } = userProfileSelector(state);

  return pending;
};


export const updateProfilePendingSelector = (state: RootState): boolean => {
  const { isProfileUpdatepending } = userProfileSelector(state);

  return isProfileUpdatepending;
};

export const updateProfileStatusSelector = (state: RootState): IStatus | null => {
  const { profileUpdateStatus } = userProfileSelector(state);

  return profileUpdateStatus;
};

export const profileUpdateModalPropsSelector = (state: RootState): any => {
  const { profileUpdateModalProps } = userProfileSelector(state);
  return profileUpdateModalProps;
};

export const isUserProfileNeedToUpdateSelector = (state: RootState): boolean | null => {
  const { isUserProfileNeedsToUpdate } = userProfileSelector(state);
  return isUserProfileNeedsToUpdate;
};

export const profileUpdateModalSelector = (state: RootState): boolean => {
  const { showProfileUpdateModal } = userProfileSelector(state);

  return showProfileUpdateModal;
};