import { IBalanceViewModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { BalanceState } from "@/fe-core/meta/types/user";
import { userSelector } from ".";

export const userBalanceSelector = (state: RootState): BalanceState => {
  const { balance } = userSelector(state);

  return balance;
};

export const userBalanceDataSelector = (
  state: RootState
): IBalanceViewModel | null => {
  const { data } = userBalanceSelector(state);

  return data;
};

export const userTotalBalanceSelector = (state: RootState): number => {
  const { data } = userBalanceSelector(state);
  const totalBalance = Number(data?.total || 0);
  return Number(totalBalance);
};

export const userWithdrawableBalanceSelector = (state: RootState): number => {
  const { data } = userBalanceSelector(state);
  const totalBalance = Number(data?.totalWithdrawableBalance || 0);
  return Number(totalBalance);
};

export const userIsBalanceLoadedSelector = (state: RootState): boolean => {
  const { status, pending, data } = userBalanceSelector(state);
  if (!data?.balance && (status === null || pending === true)) {
    return false;
  }
  return true;
};
