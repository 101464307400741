// Use this to control hardcoded sections of the app
export const devControl = {
  DISABLE_LAYOUT_REDIRECT: true,
};

export const bonusSlugs = {
  ACTIVE: "active",
  ELIGIBLE: "eligible",
  HISTORY: "history",
};

export const INPUT_STATUSES = {
  NOT_VALIDATED: "not-validated",
  VALID: "valid",
  ERROR: "error",
};

export const SCREEN_LEVELS = {
  MOBILE: 0, // < 600
  TABLET: 1, // > 600 && < 992
  DESKTOP: 2, // >992
  DESKTOP_LARGE: 3, // >1400
};

export enum timeOuts {
  WEBAPI_CALL_TIMEOUT = 10 * 1000,
  DEFAULT_STORAGE_TIMEOUT = 24 * 60 * 60,
}

export enum storageKeys {
  SESSION_ID = "SESSION_ID",
  AFFILIATE_MARKER = "AFFILIATE_MARKER",
  CID = "cid",
  LOCALE = "NEXT_LOCALE",
  COUNTRY = "NEXT_COUNTRY",
  THEME = "NEXT_THEME",
  RETURN_URL = "RETURN_URL",
}

export enum headerKeys {
  USER_ID = "X-playerId",
  SESSION_ID = "X-SessionId",
  LOCALE = "X-Locale",
}

export interface ICurrencies {
  [key: string]: string;
}

export const currencies: ICurrencies = {
  USD: "USD",
  JPY: "JPY",
  CNY: "CNY",
  VND: "VND",
  INR: "INR",
  EUR: "EUR",
};

export enum promotionTypes {
  CLAIM = "claim",
  DEPOSIT = "deposit",
  NO_ACTION = "no action",
}

export enum promotionButtonTypes {
  CLAIM = "claim",
  DEPOSIT = "deposit",
  REGISTER = "register",
  CUSTOM = "custom",
  NO_BUTTON = "noButton",
}

export interface IMonthNames {
  [key: string]: { label: string; translationId: string };
}

export const monthNames: IMonthNames = {
  0: { label: "January", translationId: "january" },
  1: { label: "February", translationId: "february" },
  2: { label: "March", translationId: "march" },
  3: { label: "April", translationId: "april" },
  4: { label: "May", translationId: "may" },
  5: { label: "June", translationId: "june" },
  6: { label: "July", translationId: "july" },
  7: { label: "August", translationId: "august" },
  8: { label: "September", translationId: "september" },
  9: { label: "October", translationId: "october" },
  10: { label: "November", translationId: "november" },
  11: { label: "December", translationId: "december" },
};

export interface IBonusStatuses {
  [key: string]: { label: string; translationId: string };
}

export const bonusStatuses: IBonusStatuses = {
  active: { label: "Active", translationId: "active" },
  expired: { label: "Expired", translationId: "expired" },
  completed: { label: "Completed", translationId: "completed" },
  released: { label: "Released", translationId: "released" },
  forfeited: { label: "Canceled", translationId: "canceled" },
  "used-up": { label: "Used Up", translationId: "used-up" },
};

export const MARKETING_CONSENT_NAMES: string[] = [
  "emailmarketing",
  "sms",
  "3dparty",
];

export const BETS_PER_PAGE = 5;
export const CASINO_BETS_PER_PAGE = 20;
export const CASINO_BETS_PER_PAGE_FOR_HOME_PAGE = 10;

export const VERSION_COOKIE = "version";

export const DEFAULT_USER_TYPE = "player";
export const DEFAULT_PREFIX = "+31";
export const DEFAULT_POSTAL_CODE = "-";
export const DEFAULT_COUNTRY_CODE = "IN";
export const DEFAULT_CURRENCY = "EUR";
export const DEFAULT_GAMING_ACCOUNT_ID = "";
export const DEFAULT_SELF_EXCLUSION_REASON = "UserRequest";
export const DEFAULT_REGION_CODE = 1923;
export const DEFAULT_REGION_NAME = "-";

export const MOBILE_CHECK_REGEX = /^07\d{8}$/;

export const EMAIL_CHECK_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((?!gamil\.com$)([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_TEST_REGEX = /(?=.*\d+)(?=.*[A-Za-z]+).{8,20}/;

export const CPN_TEST_REGEX =
  /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99|80)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/;
/* /^\d{1}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d| 5[0-2]|99)\d{4}$/ */

export const SOCKET_CONNECTION_MAX_ATTEMPTS = 3;

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_DATE_FORMAT_PICKER = "DD-MM-YYYY";

export const GAME_ID_SEPARATOR = "$$";

export const IMAGE_SIZES = {
  SMALL: "S",
  MEDIUM: "M",
  LARGE: "L",
  EXTRA_LARGE: "XL",
};

export const MAX_FILE_SIZE = 10485760;

export const DEFAULT_DEPOSIT_AMOUNTS = {
  EUR: [30, 50, 100],
  USD: [30, 50, 100],
  INR: [500, 1000, 10000, 50000],
  THB: [20, 500, 1000, 5000],
  MYR: [25, 50, 200, 1000],
  USDT: [5, 20, 50, 200],
  default: [30, 50, 100],
};

export const DEFAULT_DEPOSIT_INPUT_AMOUNT = {
  EUR: 100,
  USD: 200,
  INR: 500,
};

export const DEFAULT_WITHDRAW_AMOUNT = 40;
