import { userSelector } from ".";
import { IRecentPlayedViewModel } from "@/fe-core/meta/interfaces/user/recentPlayed";
import { RootState } from "@/fe-core/meta/types/root";
import { RecentPlayedState } from "@/fe-core/meta/types/user";

export const userRecentPlayedSelector = (
  state: RootState
): RecentPlayedState => {
  const { recentPlayed } = userSelector(state);

  return recentPlayed;
};

export const userRecentPlayedDataSelector = (
  state: RootState
): IRecentPlayedViewModel | null => {
  const { data } = userRecentPlayedSelector(state);

  return data;
};

export const userRecentPlayedPendingSelector = (state: RootState): boolean => {
  const { pending } = userRecentPlayedSelector(state);

  return pending;
};
