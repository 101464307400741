import { RootState, StatusState } from "@/fe-core/meta/types/root";
import { PasswordState } from "@/fe-core/meta/types/user";
import { userSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import {
  IPasswordPolicyViewModel,
  IResetPasswordIsKeyAvailableViewModel,
} from "@/fe-core/meta/interfaces/user";

export const userPasswordStateSelector = (state: RootState): PasswordState => {
  const { password } = userSelector(state);

  return password;
};

export const changePasswordActiveModalSelector = (
  state: RootState
): boolean => {
  const { showChangePassword } = userPasswordStateSelector(state);

  return showChangePassword;
};

export const userChangePasswordSelector = (state: RootState): StatusState => {
  const { changePassword } = userPasswordStateSelector(state);

  return changePassword;
};

export const userChangePasswordPendingSelector = (
  state: RootState
): boolean => {
  const { pending } = userChangePasswordSelector(state);

  return pending;
};

export const userChangePasswordStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = userChangePasswordSelector(state);

  return status;
};

export const userResetPasswordSendEmailSelector = (
  state: RootState
): StatusState => {
  const { resetPasswordSendEmail } = userPasswordStateSelector(state);

  return resetPasswordSendEmail;
};

export const userResetPasswordSendEmailPendingSelector = (
  state: RootState
): boolean => {
  const { pending } = userResetPasswordSendEmailSelector(state);

  return pending;
};

export const userResetPasswordSendEmailStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = userResetPasswordSendEmailSelector(state);

  return status;
};

export const userResetPasswordIsKeyAvailableSelector = (
  state: RootState
): IResetPasswordIsKeyAvailableViewModel => {
  const { resetPasswordIsKeyAvailable } = userPasswordStateSelector(state);

  return resetPasswordIsKeyAvailable;
};

export const userResetPasswordIsKeyAvailablePendingSelector = (
  state: RootState
): boolean => {
  const { pending } = userResetPasswordIsKeyAvailableSelector(state);

  return pending;
};

export const userResetPasswordIsKeyAvailableStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = userResetPasswordIsKeyAvailableSelector(state);

  return status;
};

export const userResetPasswordSelector = (state: RootState): StatusState => {
  const { resetPassword } = userPasswordStateSelector(state);

  return resetPassword;
};

export const userResetPasswordPendingSelector = (state: RootState): boolean => {
  const { pending } = userResetPasswordSelector(state);

  return pending;
};

export const userResetPasswordStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = userResetPasswordSelector(state);

  return status;
};

export const userPasswordRuleSelector = (
  state: RootState
): IPasswordPolicyViewModel => {
  const { rule } = userPasswordStateSelector(state);

  return rule;
};
