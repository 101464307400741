import { userSelector } from ".";
import { IUserConsentItem } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { ConsentsState } from "@/fe-core/meta/types/user";

export const userConsentsSelector = (state: RootState): ConsentsState => {
  const { consents } = userSelector(state);

  return consents;
};

export const userConsentsDataSelector = (
  state: RootState
): IUserConsentItem[] | null => {
  const {
    data: { userConsents },
  } = userConsentsSelector(state);

  return userConsents;
};

export const userConsentsPendingSelector = (state: RootState): boolean => {
  const { pending } = userConsentsSelector(state);

  return pending;
};

export const updateUserConsentsPendingSelector = (state: RootState): boolean => {
  const { isUpdatePending } = userConsentsSelector(state);

  return isUpdatePending;
};
