import { Error as AutoBahnError } from "autobahn";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { createCipheriv, createDecipheriv, pbkdf2Sync } from "crypto";
import { NextApiRequest, NextApiResponse } from "next";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  CPN_TEST_REGEX,
  EMAIL_CHECK_REGEX,
  headerKeys,
  MAX_FILE_SIZE,
} from "@/config/general";
import {
  IQuickSignupPayload,
  ISignupPayload,
  IUserMobile,
  IValidateCnpViewModel,
  IValidateEmailViewModel,
  IValidateMobileViewModel,
  IValidateUsernameViewModel,
  IYearOptionModel,
} from "@/fe-core/meta/interfaces/user";
import { differenceInYears, isValid } from "date-fns";
import { NextRequest, NextResponse } from "next/server";
import { IPromotionCTAVerify, IPromotionCTOVerify } from "../meta/interfaces/promotions";
import { ADDRESS_TEST_REGEX, CITY_PROVINCE_TEST_REGEX, POSTAL_CODE_TEST_REGEX } from "@/config/profile";
import { isUser18 } from "@/config/signup";
import Storage, { CookiesStorage } from "./storage";
import CryptoJS from 'crypto-js';
import dayjs from "dayjs";

export const validateAccountInformationUsername = (
  username: string,
  t: Function
): string | null => {
  if (!username) {
    return t("username-is-mandatory");
  }

  return null;
};

export const validateAccountInformationUsernameWithNoSpecialChar = (
  username: string,
  regularExpression: RegExp,
  t: Function,
  minLength?: number
): string | null => {
  if (!username) {
    return t("username-is-mandatory");
  }
  if (!regularExpression.test(username) || (minLength && username?.length < minLength)) {
    return t("username-format-error");
  }
  return null;
};

export const validateAccountInformationDocumentNumber = (
  docNumber: string,
  t: Function
): string | null => {
  if (!docNumber) {
    return t("document-number-is-mandatory");
  }
  return null;
};

export const validateAccountInformationEmail = (
  email: string,
  t: Function
): string | null => {
  if (!email) {
    return t("email-is-mandatory");
  }

  if (!EMAIL_CHECK_REGEX.test(email)) {
    return t("email-is-invalid");
  }

  return null;
};

export const validateAccountInformationPassword = (
  password: string,
  regularExpression: RegExp,
  t: Function
): string | null => {
  if (!password) {
    return t("password-is-mandatory");
  }

  if (!regularExpression.test(password)) {
    return t("password-format-error");
  }

  return null;
};

export const validateAccountInformationConfirmPassword = (
  password: string,
  confirmPassword: string,
  regularExpression: RegExp,
  t: Function
): string | null => {
  if (!confirmPassword) {
    return t("password-confirmation-is-mandatory");
  }

  if (password != confirmPassword) {
    return t("passwords-do-not-match");
  }

  if (
    !regularExpression.test(password) ||
    !regularExpression.test(confirmPassword)
  ) {
    return t("password-format-error");
  }

  return null;
};

export const validateAccountInformationMobile = (
  mobile: string | IUserMobile,
  t: Function
): string | null => {
  if (!mobile) {
    return t("phone-number-is-mandatory");
  } else if (mobile && mobile?.toString().length < 5) {
    return t("phone-number-is-invalid");
  }

  //@todo
  // if (!MOBILE_CHECK_REGEX.test(mobile)) {
  //   return 'Numărul de telefon este invalid'
  // }

  return null;
};

export const validateAccountInformationPayload = (
  payload: ISignupPayload,
  confirmPassword: string,
  regularExpression: RegExp,
  t: Function
): string | null => {
  const usernameValidationError = validateAccountInformationUsername(
    payload.username,
    t
  );
  if (usernameValidationError) {
    return usernameValidationError;
  }

  const emailValidationError = validateAccountInformationEmail(
    payload.email,
    t
  );
  if (emailValidationError) {
    return emailValidationError;
  }

  const passwordValidationError = validateAccountInformationPassword(
    payload.password,
    regularExpression,
    t
  );
  if (passwordValidationError) {
    return passwordValidationError;
  }

  const confirmPasswordValidationError =
    validateAccountInformationConfirmPassword(
      payload.password,
      confirmPassword,
      regularExpression,
      t
    );
  if (confirmPasswordValidationError) {
    return confirmPasswordValidationError;
  }

  const mobileValidationError = validateAccountInformationMobile(
    payload.mobile,
    t
  );
  if (mobileValidationError) {
    return mobileValidationError;
  }

  return null;
};

export const getAccountInformationInstantValidationError = (
  usernameData: IValidateUsernameViewModel | null,
  emailData: IValidateEmailViewModel | null,
  mobileData: IValidateMobileViewModel | null,
  t: Function
): string | null => {
  if (usernameData && !usernameData.isAvailable) {
    return t("already-used-username-feedback");
  }
  if (emailData && !emailData.isAvailable) {
    return t("already-used-email-feedback");
  }
  if (mobileData && !mobileData.isAvailable) {
    return t("already-used-phone-number-feedback");
  }
  return null;
};

export const validatePersonalInformationFullName = (
  fullName: string,
  t: Function
): string | null => {
  if (!fullName) {
    return t("full-name-is-mandatory");
  }

  return null;
};

export const validateDocumentIssueDate = (
  date: string,
  t: Function
): string | null => {
  if (!date) {
    return t("issue-date-is-mandatory");
  }

  const issueDate = dayjs(date);
  if (!issueDate.isBefore(dayjs())) return t("issue-date-must-be-in-past");

  return null;
};

export const validateDocumentExpiryDate = (
  date: string,
  t: Function
): string | null => {
  if (!date) {
    return t("expiry-date-is-mandatory");
  }

  const expiryDate = dayjs(date);
  if (expiryDate.isBefore(dayjs())) return t("expiry-date-must-be-in-future");

  return null;
};

export const validateDocumentFile = (
  files: FileList | null | undefined,
  acceptedFileTypes: string[] = [],
  isMultiFileRequired: boolean,
  t: Function
): string | null => {
  if (!files) {
    return t("document-file-required");
  }

  if (isMultiFileRequired && files?.length <= 1) {
    return t("multiple-files-are-required");
  }

  const validateFileType = (file: File) => {
    return acceptedFileTypes.includes(file.type);
  };

  const invalidSize = Array.from(files).find(
    (file) => file.size > MAX_FILE_SIZE
  );
  if (invalidSize) return t("invalid-file-size");

  const invalidFiles = Array.from(files).filter(
    (file) => !validateFileType(file)
  );

  if (invalidFiles.length > 0) {
    return t("invalid-document-type");
  } else {
    return null;
  }
};

export const validateDocumentNumber = (
  documentNumber: string,
  t: Function
): string | null => {
  if (!documentNumber) {
    return t("document-number-is-mandatory");
  }

  return null;
};

export const validatePersonalInformationFullNameIncludeFNAndLN = (
  fullName: string,
  t: Function
): string | null => {
  if (!fullName) {
    return t("full-name-is-mandatory");
  }

  if (!fullName.includes(" ")) {
    return t("enter-last-name-with-one-space");
  }

  const [firstName, ...lastNameArray] = fullName.split(" ");
  const lastName = lastNameArray.join(" ");
  if (!firstName || !lastName) {
    return t("please-enter-valid-full-name");
  }
  return null;
};

export const validatePersonalInformationLastName = (
  lastName: string,
  t: Function
): string | null => {
  if (!lastName) {
    return t("last-name-is-mandatory");
  }
  if (!lastName.match(/[\p{L}-]+/gu)) {
    return t("last-name-is-incorrect");
  }
  return null;
};

export const validatePersonalInformationFirstName = (
  firstName: string,
  t: Function
): string | null => {
  if (!firstName) {
    return t("first-name-is-mandatory");
  }
  if (!firstName.match(/[\p{L}-]+/gu)) {
    return t("first-name-is-incorrect");
  }

  return null;
};

export const validatePersonalInformationKatakana = (
  alias: string,
  t: Function
): string | null => {
  const jaRegex = /^[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]*$/;

  if (!alias) {
    return t("alias-is-mandatory");
  }
  if (!jaRegex.test(alias)) {
    return t("alias-is-incorrect");
  }
  if (alias.length < 2 || alias.length > 30) {
    return t("alias-is-not-correct-length");
  }

  return null;
};
export const validatePersonalInformationAlias = (
  alias: string,
  t: Function
): string | null => {
  if (!alias) {
    return t("alias-is-mandatory");
  }
  if (!alias.match(/[\p{L}-]+/gu)) {
    return t("alias-is-incorrect");
  }

  return null;
};

export const validatePersonalInformationCnp = (
  cnp: string,
  t: Function
): string | null => {
  if (!cnp) {
    return t("personal-identification-number-is-mandatory");
  }

  const cnpGenderDigit = parseInt(cnp[0], 10);

  if (!CPN_TEST_REGEX.test(cnp)) {
    return t("personal-identification-number-is-invalid");
  } else if (cnpGenderDigit === 7 || cnpGenderDigit === 8) {
    return t("registration-for-romanian-citizens");
  } else {
    const CONTROL = "279146358279";

    let sum = 0;
    let controlDigit = 0;

    for (let i = 0; i < 12; i++) {
      sum += parseInt(cnp[i], 10) * parseInt(CONTROL[i], 10);
    }
    controlDigit = sum % 11;
    if (controlDigit === 10) {
      controlDigit = 1;
    }

    if (controlDigit !== parseInt(cnp[12], 10)) {
      return t("personal-identification-number-is-invalid");
    }
  }

  return null;
};

export const validatePersonalInformationCountry = (
  country: string | undefined,
  t: Function
): string | null => {
  if (!country) {
    return t("country-is-mandatory");
  }

  return null;
};

export const validatePersonalInformationCurrency = (
  currency: string | undefined | null,
  t: Function
): string | null => {
  if (!currency) {
    return t("currency-is-mandatory");
  }

  return null;
};

export const validatePersonalInformationAddress = (
  address: string,
  t: Function
): string | null => {
  if (!address) {
    return t("address-is-mandatory");
  }
  if (!address.match(/[\p{L}-]+/gu)) {
    return t("address-is-incorrect");
  }
  return null;
};

export const validatePersonalInformationAddressWithNoSpecialChar = (
  address: string,
  t: Function
): string | null => {
  if (!address) {
    return t("address-is-mandatory");
  }
  if (!ADDRESS_TEST_REGEX.test(address)) {
    return t("address-is-incorrect");
  }
  return null;
};

export const validatePersonalInformationCpf = (
  cpfString: string,
  t: Function
): string | null => {
  let validated = false; // Start with false

  if (!cpfString) {
    return t("cpf-is-mandatory");
  }

  // Checking cpf lenght
  if (cpfString.length !== 14 && cpfString.length !== 11) {
    return t("cpf-is-not-correct-length");
  }

  // Checking for "formated cpf (000.000.000-00)" and replace "points"
  if (cpfString.length === 14) {
    cpfString = cpfString.replace(".", "");
    cpfString = cpfString.replace(".", "");
    cpfString = cpfString.replace("-", "");
  }

  // Variables to check cpf valid (sum and rest)
  let sum = 0,
    rest = 0;

  // Checking for "null" CPF
  if (cpfString === "00000000000") {
    return t("cpf-is-not-valid");
  }

  // sum numbers
  for (let i = 1; i <= 9; ++i) {
    sum += parseInt(cpfString.substring(i - 1, i)) * (11 - i);
  }

  // Getting rest
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) {
    rest = 0;
  }

  if (rest != parseInt(cpfString.substring(9, 10))) {
    return t("cpf-is-not-valid");
  }

  return null;
};

export const validatePersonalInformationPostalCode = (
  postalCode: string,
  t: Function
): string | null => {
  if (!postalCode) {
    return t("postalCode-is-mandatory");
  }
  if (!postalCode.match(/^[A-Za-z\d-]{2,10}$/)) {
    return t("postalCode-is-incorrect");
  }

  return null;
};

export const validatePersonalInformationPostalCodeWithNoSpecialChar = (
  postalCode: string,
  t: Function
): string | null => {
  if (!postalCode) {
    return t("postalCode-is-mandatory");
  }
  if (!POSTAL_CODE_TEST_REGEX.test(postalCode)) {
    return t("postalCode-is-incorrect");
  }

  return null;
};

export const validatePersonalInformationProvinceWithNoSpecialChar = (
  province: string,
  t: Function
): string | null => {
  if (!province) {
    return t("province-is-mandatory");
  }
  if (!CITY_PROVINCE_TEST_REGEX.test(province)) {
    return t("province-is-incorrect");
  }

  return null;
};

export const validatePersonalInformationCity = (
  city: string,
  t: Function
): string | null => {
  if (!city) {
    return t("city-is-mandatory");
  }
  if (!city.match(/[\p{L}-]+/gu)) {
    return t("city-is-incorrect");
  }

  return null;
};

export const validatePersonalInformationCityWithNoSpecialChar = (
  city: string,
  t: Function
): string | null => {
  if (!city) {
    return t("city-is-mandatory");
  }
  if (!CITY_PROVINCE_TEST_REGEX.test(city)) {
    return t("city-is-incorrect");
  }

  return null;
};

export const validatePersonalInformationBirthdate = (
  birthdate: string | null,
  t: Function
) => {
  if (birthdate?.includes("-")) {
    if (!birthdate) {
      return t("birthdate-is-invalid");
    }
    const [day, month, year] = birthdate.split("-");
    const result = isValid(
      new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))
    );

    if (result) {
      const difference = differenceInYears(
        new Date(),
        new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))
      );
      if (difference < 18) {
        return t("birthdate-is-not-over-18");
      }
    } else {
      return t("birthdate-is-invalid");
    }

    return null;
  } else {
    if (!birthdate) {
      return t("birthdate-is-invalid");
    }
    const currentDate = new Date();
    const birthdateParts = birthdate.split("/");
    const birthdateObj = new Date(
      parseInt(birthdateParts[2]),
      parseInt(birthdateParts[0]) - 1,
      parseInt(birthdateParts[1])
    );
    const ageDiff = currentDate.getFullYear() - birthdateObj.getFullYear();
    const monthDiff = currentDate.getMonth() - birthdateObj.getMonth();
    const dayDiff = currentDate.getDate() - birthdateObj.getDate();
    const isOldEnough =
      ageDiff > 18 ||
      (ageDiff === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));
    if (parseInt(birthdateParts[0]) > 12) {
      return t("birthdate-is-invalid");
      // setBirthErr("Invalid month. Please enter a month between 01 and 12.");
    } else if (parseInt(birthdateParts[1]) > 31) {
      return t("birthdate-is-invalid");
      // setBirthErr("Invalid day. Please enter a day between 01 and 31.");
    } else if (ageDiff > 99) {
      return t("birthdate-is-invalid");
    } else if (!isOldEnough) {
      return t("birthdate-is-not-over-18");
      // setBirthErr("You must be at least 18 years old.");
    } else {
      return null;
    }
  }
};

export const validatePersonalInformationPayload = (
  payload: IQuickSignupPayload | ISignupPayload,
  t: Function
): string | null => {
  const firstNameValidationError = validatePersonalInformationFirstName(payload.firstName, t);
  if (firstNameValidationError) return firstNameValidationError;

  const lastNameValidationError = validatePersonalInformationLastName(payload.lastName, t);
  if (lastNameValidationError) return lastNameValidationError;

  const isOver18 = isUser18(payload?.birthDate || "")
  if (!isOver18) return t("must-over-18-years-old");

  const currencyValidationError = validatePersonalInformationCurrency(payload.currency, t);
  if (currencyValidationError) return currencyValidationError;

  const addressValidationError = validatePersonalInformationAddressWithNoSpecialChar(payload.address, t);
  if (addressValidationError) return addressValidationError;

  const cityValidationError = validatePersonalInformationCity(payload.city, t);
  if (cityValidationError) return cityValidationError;

  const countryValidationError = validatePersonalInformationCountry(payload.country, t);
  if (countryValidationError) return countryValidationError;

  return null;
};

export const getPersonalInformationInstantValidationError = (
  cnpData: IValidateCnpViewModel | null,
  t: Function
): string | null => {
  if (cnpData && !cnpData.isAvailable) {
    return t("cnp-number-already-use");
  }
  return null;
};

export const parseCnpBirthDay = (cnp: string): string => cnp.substring(5, 7);

export const parseCnpBirthMonth = (cnp: string): string => cnp.substring(3, 5);

export const parseCnpBirthYear = (cnp: string): number => {
  const controlDigit = parseInt(cnp.substring(0, 1), 10);
  const cnpYear = parseInt(cnp.substring(1, 3), 10);
  let birthYear = 1900 + cnpYear;

  if (controlDigit === 5 || controlDigit === 6) {
    birthYear = 2000 + cnpYear;
  }

  return birthYear;
};

export const parseCnpTitle = (cnp: string): string => {
  const firstDigit = parseInt(cnp.substring(0, 1));
  const title = firstDigit % 2 === 1 ? "Mr." : "Mrs.";

  return title;
};

export const parseApiMessageCode = ({ code, error }) => {
  if (!error) {
    return 100;
  }

  const errorIndex = error.indexOf("errorCode:");
  if (errorIndex !== -1) {
    var numb = error
      ?.substring(error.indexOf("errorCode:"), errorIndex + 15)
      .match(/\d/g);
    return numb ? numb.join("") : 100;
  }
};

export const parseApiError = ({
  message,
  //@ts-ignore
  errors,
}: IErrorPayload): { statusCode?: number; error: IErrorPayload } => {
  if (!message && (!errors || !Array.isArray(errors))) {
    return {
      error: {
        // t("unknown-error-occurred")
        message: "Error occurred. This incident has been reported.",
      },
    };
  }

  if (errors && Array.isArray(errors)) {
    const firstError = errors[0];
    if (firstError && firstError.error === "ALREADY_USED") {
      return {
        error: {
          message: `The field '${firstError.field}' is already in use. Please try a different value.`,
        },
      };
    } else {
      const errorMessages = errors.map((err) => {
        return `${err.field}: ${err.error}`;
      }).join(", ");
  
      return {
        error: {
          message: `Validation errors: ${errorMessages}`,
        },
      };
    }
  }

  const errorIndex = message?.indexOf("errorMessage:");
  if (errorIndex !== -1) {
    return {
      error: {
        message: message?.substring(message.indexOf("errorMessage:") + 13),
      },
    };
  }

  if (
    message?.indexOf("Invalid Session") !== -1 ||
    message?.indexOf("does not match userID with xSessionId") !== -1 ||
    message?.indexOf("Failed to validate playerId") !== -1
  ) {
    return {
      statusCode: 401,
      error: {
        message: message?.substring(message.indexOf("errorMessage:") + 13),
      },
    };
  }

  if (message?.indexOf("This attribute must be unique") !== -1) {
    return {
      error: {
        message: "This attribute must be unique",
      },
    };
  }

  if (
    message?.indexOf(
      "S-a atins numarul maxim de coduri pentru acest numar de telefon."
    ) !== -1
  ) {
    return {
      error: {
        message:
          "S-a atins numarul maxim de coduri pentru acest numar de telefon.",
      },
    };
  }

  if (message?.indexOf("Acest numar este deja folosit.") !== -1) {
    return {
      error: {
        message: "Acest numar este deja folosit.",
      },
    };
  }

  return {
    error: {
      message: "Error occurred. This incident has been reported.",
    },
  };
};

const mapErrorMessage = (errorMessage: string): string => {
  if (
    errorMessage === "" ||
    errorMessage === null ||
    errorMessage === undefined
  ) {
    return "Error occurred. This incident has been reported.";
  }

  // TODO parse errors as needed
  switch (errorMessage) {
    // session
    case "The login failed. Please check your username and password.":
      return "Numele de utilizator și/sau parola greșite.";

    case "The login failed. Your user account is blocked. If you are a self excluded user you can contact support for withdrawing money.":
      return "Conectarea a eșuat. Acest user este blocat. Pentru mai multe detalii va rugăm să contactați Asistență Clienți!";

    case "You are not allowed to login because you are from a restricted country. If you have any problem, please contact support.":
      return "Nu vă puteți conecta deoarece platforma de joc poate fi accesată doar de pe teritoriul României.";

    case `'usernameOrEmail' cannot be an empty string ("") or start with the null character.\r\nParameter name: usernameOrEmail`:
      return "Trebuie să completezi username-ul și parola pentru a te loga!";

    case `'password' cannot be an empty string ("") or start with the null character.\r\nParameter name: password`:
      return "Trebuie să completezi parola pentru a te loga!";

    // bonus
    case "Failed to trigger claim bonus, detail: `InvalidBonusCode`":
      return "Codul de bonus este invalid";
    case "[bonusCode] is missing":
      return "Codul de bonus lipsește";

    // sms activation:
    case "This attribute must be unique":
      return "Numărul de telefon este deja folosit.";

    case "S-a atins numarul maxim de coduri pentru acest numar de telefon.":
      return "Ai atins numărul maxim de solicitări. Introdu ultimul cod primit prin SMS.";

    case errorMessage:
      return errorMessage;

    default:
      return "Error occurred. This incident has been reported.";
  }
};

export const parseFrontendError = (
  error: unknown,
  placeholderMessage: string | undefined = undefined
): IErrorPayload => {
  if (error instanceof Error && error.message) {
    if (process.env.NODE_ENV === "development") {
      console.error(error.message);
    }
    return { message: mapErrorMessage(error.message) };
  }
  let status = '';
  let detailedMessage = '';
  let advicesMessage = '';
  if (typeof error === 'object' && error !== null) {
    const errorObj = error as { status?: string; message?: string; errors?: Record<string, string>[]; advices?: Record<string, string>[] };
    if (errorObj.status) {
      status = `Status: ${errorObj.status}. `;
    }
    if (errorObj.errors && Array.isArray(errorObj.errors)) {
      detailedMessage = errorObj.errors.map(err =>
        Object.entries(err).map(([key, value]) => `${key}: ${value}`).join('; ')
      ).join('; ');
    }
    if (errorObj.advices && Array.isArray(errorObj.advices)) {
      advicesMessage = errorObj.advices.map(advice =>
        Object.entries(advice).map(([key, value]) => `${key}: ${value}`).join('; ')
      ).join('; ');
    }
  }
  return {
    message:
      `${status}${detailedMessage} ${advicesMessage}`.trim() ||
      placeholderMessage ||
      "Error occurred. This incident has been reported.",
  };
};

const getEncryptionMeta = () => {
  const algorithm = process.env.ENCRYPTION_ALGORITHM || "";
  const encryptionKey = process.env.ENCRYPTION_KEY || "";
  const salt = process.env.ENCRYPTION_SALT || "";

  const key = pbkdf2Sync(encryptionKey, salt, 1000000, 32, "sha512");
  const iv = key.subarray(0, 16);

  return { algorithm, key, iv };
};

export const encryptString = (toEncrypt: string): string => {
  const { algorithm, key, iv } = getEncryptionMeta();

  const cipher = createCipheriv(algorithm, key, iv);
  const encrypted =
    cipher.update(toEncrypt, "utf-8", "base64") + cipher.final("base64");

  const base64ed = Buffer.from(encrypted).toString("base64");

  return base64ed;
};

export const decryptString = (toDecrypt: string): string => {
  const { algorithm, key, iv } = getEncryptionMeta();

  const nonBase64ed = Buffer.from(toDecrypt, "base64").toString("utf-8");
  const decipher = createDecipheriv(algorithm, key, iv);
  const decrypted =
    decipher.update(nonBase64ed, "base64", "utf-8") + decipher.final("utf-8");

  return decrypted;
};

export const encodeToB64 = (toEncode: string): string => {
  const base64data = Buffer.from(toEncode).toString("base64");

  return base64data;
};

export const checkApiRequirements = (
  req: NextApiRequest | NextRequest,
  res: NextApiResponse | NextResponse,
  allowedMethods: methodTypes[],
  requiredHeadersKeys: headerKeys[] = []
): boolean => {
  if (!allowedMethods.includes(req.method as methodTypes)) {
    // res.status(405).json({ message: "Method not allowed." });
    console.log({ message: "Method not allowed." });
    return false;
  }
  let requestHeaders: Headers;
  if ("headers" in req && typeof req.headers === "object") {
    requestHeaders = new Headers(req.headers as HeadersInit);
  } else {
    const incomingHeaders = req.headers;
    const headersInit: [string, string][] = Object.entries(incomingHeaders);
    requestHeaders = new Headers(headersInit);
  }
  // const requestHeaders = new Headers(req.headers);
  const missingHeaderErrors = requiredHeadersKeys.reduce(
    (accumulator: string[], requiredHeaderKey) => {
      const requiredHeader = requestHeaders.has(
        requiredHeaderKey.toLowerCase()
      );
      if (!requiredHeader) {
        accumulator.push(
          `${accumulator.length === 0 ? "M" : "m"
          }issing ${requiredHeaderKey} header`
        );
      }
      return accumulator;
    },
    []
  );
  if (missingHeaderErrors.length > 0) {
    // res.status(400).json({ message: missingHeaderErrors.join(", ") + "." });
    console.log({ message: missingHeaderErrors.join(", ") + "." });
    return false;
  }

  return true;
};

export function toFixedNoRounding(n: number, decimals = 2): string {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + decimals + "})?", "g");
  const regMatches = n.toString().match(reg);

  const nr = regMatches?.[0] ? parseFloat(regMatches?.[0]) : n;

  return new Intl.NumberFormat("ro-RO", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(nr);
}

export function capitalizeFirstLetter(inputString: string): string {
  return inputString.replace(
    /^(.)(.*)$/,
    (_, firstChar, restOfString) =>
      firstChar.toUpperCase() + restOfString.toLowerCase()
  );
}
interface MyObject {
  [key: string]: any;
}
export const removeBlankValuesFromObject = (obj: MyObject) => {
  const newObj: MyObject = {};
  for (const key in obj) {
    if (obj[key] !== "") {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const getEMLanguageCode = (locale: string) => {
  switch (locale) {
    case "ja":
      return "ja";
    case "zh":
      return "zh-cn";
    case "vi":
      return "vi";
    case "en-ie":
      return "en-ie";
    case "en-au":
      return "en-au";
    case "en-nz":
      return "en-nz";
    case "en-ca":
      return "en-ca";
    case "en-in":
      return "en-in";
    case "de-de":
      return "de-de";
    case "de-ch":
      return "de-ch";
    default:
      return locale;
  }
};

export const generateYearRange = (isForSelectOptions?: boolean) => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 18;
  const endYear = currentYear - 100;

  const years = [];
  for (let year = startYear; year >= endYear; year--) {
    years.push(year);
  }
  if (isForSelectOptions) {
    return years.map((item) => ({
      value: item,
      label: item,
    })) as IYearOptionModel[];
  }
  return years;
};

export const MONTH_OPTIONS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
].map((item, i) => ({
  value: i + 1,
  label: item,
}));

export const DATE_OPTIONS = new Array(31).fill(null).map((_, i) => ({
  value: i + 1,
  label: i + 1,
}));

export const getPromotionCTO = ({ promotion, t, isUserAuthenticated, isUserIdentified, defaultLink }: IPromotionCTOVerify) => {
  let redirectionLabel = t("learn-more")
  let redirectionLink = defaultLink

  if (isUserAuthenticated) {
    if (promotion?.CTO?.labelAuthenticated) redirectionLabel = promotion.CTO.labelAuthenticated
    if (promotion?.CTO?.linkAuthenticated) redirectionLink = promotion.CTO.linkAuthenticated
  } else if (isUserIdentified) {
    if (promotion?.CTO?.labelIdentified) redirectionLabel = promotion.CTO.labelIdentified
    if (promotion?.CTO?.linkIdentified) redirectionLink = promotion.CTO.linkIdentified
  } else {
    if (promotion?.CTO?.labelPublic) redirectionLabel = promotion.CTO.labelPublic
    if (promotion?.CTO?.linkPublic) redirectionLink = promotion.CTO.linkPublic
  }

  return { redirectionLabel, redirectionLink }
}

export const OK_STATUS = "SUCCESS"
export const REDIRECT_STATUS = "REDIRECT"



export const OMEGA_STATUS_TYPES = {
  NOT_LOGGED_IN_ERROR_STATUS: "NOT_LOGGED_IN_ERROR",
  ACCESS_TOKEN_INACTIVE_FOR_LOGIN: "ACCESS_TOKEN_INACTIVE_FOR_LOGIN",
  SUCCESS: "SUCCESS",
  SYSTEM_ERROR: "SYSTEM_ERROR",
  TOO_MANY_RECORDS: "TOO_MANY_RECORDS",
  ITEM_NOT_FOUND: "ITEM_NOT_FOUND",
  VALIDATION_FAIL: "VALIDATION_FAIL",
  CONTINUE_TO_PAYMENT_SITE_STATUS: "CONTINUE_TO_PAYMENT_SITE",
  ACCESS_TOKEN_PENDING_FOR_LOGIN: "ACCESS_TOKEN_PENDING_FOR_LOGIN"
}

export const NOT_LOGGED_IN_ERROR_STATUS = "NOT_LOGGED_IN_ERROR"

export const FAIL_VERIFY = 'FAIL_VERIFY'

export const FAIL_TEMP_LOCK = 'FAIL_TEMP_LOCK'

export const USERNAME_AVAILABLE_STATUS = 'USERNAME_AVAILABLE'

export const decryptedJsonData = (key: string) => {
  try {
    const stringifiedData = Storage.get(key)?.toString()
    const dataBytes = stringifiedData ? CryptoJS.AES.decrypt(stringifiedData, process.env.NEXT_PUBLIC_CRYPTO_PUBLIC_KEY as string) : null
    return dataBytes ? JSON.parse(dataBytes?.toString(CryptoJS.enc.Utf8)) : null
  } catch (error) {
    return null
  }
}

export const decryptedStringData = (key: string) => {
  try {
    const stringifiedData = Storage.get(key)?.toString()
    const dataBytes = stringifiedData ? CryptoJS.AES.decrypt(stringifiedData, process.env.NEXT_PUBLIC_CRYPTO_PUBLIC_KEY as string) : null
    return dataBytes ? dataBytes?.toString(CryptoJS.enc.Utf8) : null
  } catch (error) {
    return null
  }
}

export const decryptedStringDataCookie = (key: string) => {
  try {
    const stringifiedData = CookiesStorage.getItem(key)?.toString()
    const dataBytes = stringifiedData ? CryptoJS.AES.decrypt(stringifiedData, process.env.NEXT_PUBLIC_CRYPTO_PUBLIC_KEY as string) : null
    return dataBytes ? dataBytes?.toString(CryptoJS.enc.Utf8) : null
  } catch (error) {
    return null
  }
}

export const encryptedJsonData = (data: any): string => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.NEXT_PUBLIC_CRYPTO_PUBLIC_KEY as string)?.toString() || "";
  } catch (error) {
    return ""
  }
}

export const encryptedStringData = (data: string): string => {
  try {
    return CryptoJS.AES.encrypt(data, process.env.NEXT_PUBLIC_CRYPTO_PUBLIC_KEY as string)?.toString() || "";
  } catch (error) {
    return ""
  }
}

export const getPromotionCTA = ({
  CTA,
  isUserAuthenticated,
  isUserIdentified,
}: IPromotionCTAVerify) => {
  let redirectionLabel = "";
  let redirectionLink = "";

  if (isUserAuthenticated) {
    if (CTA?.labelAuthenticated) redirectionLabel = CTA.labelAuthenticated;
    if (CTA?.linkAuthenticated) redirectionLink = CTA.linkAuthenticated;
  } else if (!!+isUserIdentified) {
    if (CTA?.labelIdentified) redirectionLabel = CTA.labelIdentified;
    if (CTA?.linkIdentified) redirectionLink = CTA.linkIdentified;
  } else {
    if (CTA?.labelPublic) redirectionLabel = CTA.labelPublic;
    if (CTA?.linkPublic) redirectionLink = CTA.linkPublic;
  }

  return { redirectionLabel, redirectionLink };
};

export const validateProcessDepositAmount = (
  amount: number | null | undefined,
  t: Function
): string | null => {
  if (!amount) return t("amount-required");
  if (amount <= 0) return t("amount-must-be-positive");
  return null;
};

export const validateProcessWithdrawAmount = (
  amount: number | null | undefined,
  t: Function,
  minAmount?: number | null | undefined,
  maxAmount?: number | null | undefined,
  withdrawableAmount?: number | null | undefined
): string | null => {
  if (!amount) return t("amount-required");
  if (amount <= 0) return t("amount-must-be-positive");

  if (
    withdrawableAmount !== null &&
    withdrawableAmount !== undefined &&
    !isNaN(withdrawableAmount) &&
    amount > withdrawableAmount
  )
    return t("cant-withdraw-more-than-wallet-amount");

  if (minAmount !== null && minAmount !== undefined && amount < minAmount) {
    return t("amount-below-minimum");
  }
  if (maxAmount !== null && maxAmount !== undefined && amount > maxAmount) {
    return t("amount-above-maximum");
  }
  return null;
};

export const parsedAmount = (amount: number | string): number => {
  // If it's a number, convert it to a string, then remove commas
  const amountStr = typeof amount === 'number' ? amount.toString() : amount;
  const cleanedAmount = amountStr.replace(/,/g, '');
  // Return the parsed number
  return Number(cleanedAmount);
}
export const mbFileSize = (size: number): string =>
  (size / 1024 / 1024).toFixed(1);

export const kbFileSize = (size: number): string => (size / 1024).toFixed(1);

export const fileSize = (size: number) =>
  +kbFileSize(size) > 512 ? `${mbFileSize(size)} MB` : `${kbFileSize(size)} KB`;
